@font-face {
  font-family: 'OpenSans-bold';
  src: local('MyFont'), url(./fonts/OpenSans-bold.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-semibold';
  src: local('MyFont'), url(./fonts/OpenSans-semibold.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-light';
  src: local('MyFont'), url(./fonts/OpenSans-light.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-regular';
  src: local('MyFont'), url(./fonts/OpenSans-regular.woff) format('woff');
}

body{
  background-color: #ecf0f3 !important;
}
.Component-tooltip {
  z-index: 1500;
  width: min-content;
  pointer-events: none;
  white-space: nowrap;
}
/* .MuiTooltip-tooltip{
  color:black;
  font-family: 'OpenSans-semibold';
} */
.MuiTooltip-tooltip{
  color:black;
  font-family: 'OpenSans-semibold' !important;
  z-index: 1500;
  /* width: min-content; */
  pointer-events: none;
  /* white-space: nowrap; */
  border-radius: 4px !important;
  background-color: white !important;
}
[role="tooltip"] {
  top: -38px !important;
}